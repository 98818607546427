/* ==========================================================================
 _global / variables
========================================================================== */

/* Colors
----------------------------------------------- */

$black:       #1A2226;
$white:       #fff;
$grey-dark:     mix($white, $black, 30%);
$grey:          mix($white, $black, 50%);
$grey-lightish: mix($white, $black, 65%);
$grey-light:    mix($white, $black, 80%);
$grey-lightest: #f9f5f2;

$primary:       #009CEB;
$secondary:     #00497f;
$tertiary:      #003754;

$brown: #aa7e55;
$brown-light: #c7a889;

$primary-light: lighten($primary, 8%);
$primary-lightest: #4dbaf1;

$error:         #ce4840;

$blue:          $primary;
$green:         #7f9b73;
$green-hover:   #526246;


/* Fonts
----------------------------------------------- */

$font-text:  'texta-narrow', -apple-system, BlinkMacSystemFont, 'Segoe UI', sans-serif;
$font-title: 'Mencken Std Text', 'mencken-std-text', 'Georgia', serif;

$base-line-height:  1.3;
$base-font-color: $black;
$grey-font-color: mix($white, $black, 32%);
$blue-font-color: #0092DB; // to be used on grey backaground

$light:   300;
$normal:  400;
$medium:  500;
$bold:    700;
$heavy:   800;


/* Menu
----------------------------------------------- */

$menu-hidden:   max-width 500px; // hamburger
$menu-visible:  501px;           // no hamburger


/* Admin bar
----------------------------------------------- */

$admin-bar-breakpoint: 783px;
$admin-bar-height-mobile: 46px;
$admin-bar-height-desktop: 32px;


/* Breakpoints
----------------------------------------------- */

$filters-small: max-width 499px;
$filters-medium: 500px 799px;
$filters-big: 800px;

$header-with-search-bar-hidden: max-width 1209px;
$header-with-search-bar-visible: 1210px;

$sub-xs: max-width 359px;
$xs:     360px;

$sub-s:  max-width 479px;
$s:      480px;

$sub-sm: max-width 639px;
$sm:     640px;

$sub-m:  max-width 719px;
$m:      720px;

$sub-l:  max-width 1023px;
$l:      1024px;

$sub-xl: max-width 1365px;
$xl:     1366px;

$sub-xxl: max-width 1919px;
$xxl:     1920px;

$textmedia-s: max-width 879px;
$textmedia-m: 880px;
$textmedia-range-m: 880px 1264px;
$textmedia-l: 1264px;

/* Transitions
----------------------------------------------- */

$ease-out-expo: cubic-bezier(0.19, 1, 0.22, 1);
$transition-fast: all 0.25s $ease-out-expo;
$transition: all 0.5s $ease-out-expo;
$transition-slow: all 1s $ease-out-expo;
