/**
 * Cookiebot
 */

// SASS variables
$cb-font-title:       'Mencken Std Text', 'mencken-std-text', 'texta-narrow', sans-serif;
$cb-font-text:        'texta-narrow', sans-serif;
$cb-primary:         #009CEB;
$cb-secondary:       #005596;
$cb-tertiary:        #003754;
$cb-base-font-color: #1A2226;

// CSS3 variables
:root {
  --cb-title-font-size-mobile: 28px;
  --cb-title-font-size-desktop: 40px;
  --cb-title-font-family: #{$cb-font-title};
  --cb-button-border-radius: 0;
  --cb-button-border: 2px solid #{$cb-primary};
  --cb-button-color: #{$cb-primary};
  --cb-button-active-color: #fff;
  --cb-button-active-background: #{$cb-tertiary};
  --cb-button-active-border: 2px solid #{$cb-tertiary};
  --cb-accept-button-color: #{$white};
  --cb-accept-button-background: #{$cb-secondary};
  --cb-accept-button-border: 2px solid #{$cb-secondary};
  --cb-accept-button-active-color: #{$white};
  --cb-accept-button-active-background: #{$cb-tertiary};
  --cb-accept-button-active-border: 2px solid #{$cb-tertiary};
  --cb-preference-accent-color: #{$cb-primary};
}

#CookieBanner {
  #CookieBannerOverlay {
    background: rgba(0, 26, 39, .6125);
  }
  #CookieBannerTitle {
    // text-transform: uppercase;
    color: $cb-primary;
    // letter-spacing: .2px;
    // margin-bottom: .375em;
  }
  #CybotCookiebotDialogBodyButtonDecline,
  #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll,
  #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection {
    // text-transform: uppercase;
    font-family: $cb-font-text;
    letter-spacing: .015em;
    font-size: 18px;
    padding-top: 12px;
    padding-bottom: 16px;
    @media (min-width: 720px) {
      font-size: 22px;
      padding-top: 14px;
      padding-bottom: 18px;
    }
  }
  #CustomCookiebotOpenDetails {
    // color: $cb-tertiary;
  }
  .cookiebanner__preference__title {
    color: $cb-base-font-color;
  }
  .cookiebanner__preference table {
    margin-top: 0;
    margin-bottom: 0;
    th {
      background: #009ceb;
      color: #fff;
    }
  }
}


/**
 * nukuhyvin.coronaria.fi fixes
 */
.SinglePageCheckout,
.DrawerContainer {

  #CookieBanner {
    #CookieBannerTitle {
      letter-spacing: normal;
      margin-bottom: 1em;
      text-transform: none;
      font-size: 1.5rem;
      @media (min-width: 720px) {
        font-size: 1.75rem;
      }
    }

    #CybotCookiebotDialogBodyButtonDecline,
    #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll,
    #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection {
      letter-spacing: normal;
      font-size: 16px;
      padding-top: 14px;
      padding-bottom: 14px;
      text-transform: none;
      @media (min-width: 720px) {
        font-size: 17px;
        padding-top: 16px;
        padding-bottom: 16px;
      }
    }

    #CybotCookiebotDialog tbody,
    #CybotCookiebotDialog tr,
    #CybotCookiebotDialog td {
      background: transparent !important;
    }

  }
}

/**
 * Decalaration
 */

 .CookieDeclaration {

  // Type
  .CookieDeclarationType {
    padding: 1rem;
    overflow: auto;

    p {
      font-size: 1rem;
      margin-bottom: 1rem;
    }

    td,
    th {
      font-size: 1rem;
      line-height: 1.5;
      padding: .5rem .25rem;
    }
  }

  // Header
  .CookieDeclarationTypeHeader {
    margin-top: 0;
  }

  // Table
  .CookieDeclarationTable {
    overflow-x: auto;
    table-layout: auto;

    th,
    td {
      min-width: 8rem;
    }

    tbody tr:last-of-type td {
      border-bottom: 0;
    }
  }
}

/**
 * Video info
 */
.cookieconsent-optout-marketing {
  & > span {
    background: $grey-lightest;
    padding: 1.5rem;
    color: $base-font-color;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  a {
    color: $primary !important;
  }
  .text-media-block & > span {
    // position: absolute;
    // top: 0;
    // left: 0;
    width: 100%;
    height: 100%;
    aspect-ratio: 16/9;
  }
  .wp-block-embed + & > span {
    padding-top: 3rem;
    padding-bottom: 3rem;
    margin-bottom: 5rem;
  }
}
